@import url(./common.scss);

body {
  font-family: "DM Sans", sans-serif;
}

.custom-checkbox .ant-checkbox + span {
  margin-left: 10px;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.remove-modal-padding {
  .ant-modal,
  .ant-modal-content {
    padding: 0;
  }
}

.form-input {
  .ant-input {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 12px, 16px, 12px, 16px !important;
    min-height: 40px !important;
  }
  .ant-picker-outlined {
    min-height: 36px !important;
  }
}

.login-grid-lines {
  background-image: linear-gradient(to right, #e8eaee 1px, transparent 1px),
    linear-gradient(to right, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to left, #e8eaee 1px, transparent 1px),
    linear-gradient(to left, #e8eaee 1px, transparent 1px);
  background-position: 510px 0, 550px 0, 0px 180px, 0px 220px, 0px 650px,
    0px 690px, -510px 0px, -550px 0px;
}

.forgot-password-grid-lines {
  background-image: linear-gradient(to right, #e8eaee 1px, transparent 1px),
    linear-gradient(to right, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to bottom, #e8eaee 1px, transparent 1px),
    linear-gradient(to left, #e8eaee 1px, transparent 1px),
    linear-gradient(to left, #e8eaee 1px, transparent 1px);
  background-position: 510px 0, 550px 0, 0px 180px, 0px 220px, 0px 545px,
    0px 585px, -510px 0px, -550px 0px;
}

 .ant-menu-inline {
  border-inline-end: none !important;
}

.google-visualization-tooltip {
  background-color: white !important;
  color: black !important;
  padding: 5px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  z-index: 9999 !important; /* Ensure the tooltip is above other elements */
  overflow: visible !important;
}