.white-color {
  filter: brightness(0) invert(1);
}

.vehicle-modal {
  .ant-modal-content {
    padding: 0px 0px 0px 0px !important;
  }
}

.group-hover-class:hover {
  .hover-primary {
    filter: brightness(0) invert(1);
  }
}

.custom-ant-btn:hover {
  background-color: #405ff2 !important;
  border-color: #405ff2 !important;
  .hover-white {
    filter: brightness(0) invert(1);
  }
}
